import { WaveSurferOptions } from 'wavesurfer.js';

export const GOOGLE_LOGIN_PROVIDER = 'Google';
export const FACEBOOK_LOGIN_PROVIDER = 'Facebook';

// * DIALOG

export const DIALOG_CONFIRM_DEFAULT_TITLE = 'Sei sicuro?';
export const DIALOG_LABLE_MISSING_FOR_SIMULATION =
  'Sembra tu stia cercando di accedere ad una sezione riservata agli studenti di Futura. Iscriviti per diventare uno studente ed avere accesso a Futura illimitata!';

// export const DIALOG_ERROR_DEFAULT_TITLE = "Errore";
export const DIALOG_ERROR_DEFAULT_TITLE = '⚠️';

export const DIALOG_INFO_DEFAULT_TITLE = 'ℹ️';

export const DIALOG_ERROR_SIMULATION_NOT_FOUND = 'Simulazione inesistente';
export const DIALOG_ERROR_SIMULATION_IN_PROGRESS = 'Questa simulazione non è stata ancora terminata';

// * GENERIC ERROR
export const ERROR_MISSING_NETWORK = 'Sembra tu non sia connesso a internet, riprova più tardi';

// * LOGIN

export const LOGIN_USER_NOT_FOUND_EXCEPTION = 'Forse non ti sei ancora registrato?\nRegistrati <a href="/register">qui<a>';
export const LOGIN_NOT_AUTHORIZED_EXCEPTION = 'Questa password è sbagliata. Crea un nuovo profilo o recupera la tua password.';
export const LOGIN_USER_NOT_CONFIRMED_EXCEPTION = 'Non hai ancora confermato questa email, controlla la tua casella postale.';
export const LOGIN_USER_DISABLED = 'Questo utente è stato disabilitato, contattaci per più informazioni.';

// * Register

export const REGISTER_USER_EXISTS_EXCEPTION = 'La tua mail è già nel nostro database! 😍\n<a href="/login">Accedi</a>';
export const REGISTER_CHECK_YOUR_EMAIL = 'Per poter accedere a Futura inserisci il codice che hai ricevuto via email.';
export const REGISTER_INVALID_PASSWORD = 'La password non è in un formato valido. Assicurati che sia lunga almeno 6 caratteri.';

// * Verification Token

export const CODE_MISMATCH_EXCEPTION = 'Codice invalido, prova di nuovo.';
export const CODE_MATCH_SUCCESS = "L'email è stata confermata. Adesso puoi fare il login con le credenziali fornite";
export const CODE_ASK_EMAIL = 'Inserisci la tua email per completare la registrazione';
export const CODE_THE_DIALOG_MUST_BE_CONFIRMED = "Per continuare è necessaria l'email.";

// * Profile

export const PROFILE_UPDATE_SUCCESS = 'Le tue informazioni sono state aggiornate con successo';

// * Video

// Percentuale da usare per considerare il video come visto;
export const PERCENT_FOR_VIDEO_WATCHED = 90;
export const PERCENT_FOR_VIDEO_SEND_GTAG_EVENT = 60;

export const CURRENCIES = [
  { name: 'Euro', display: '€', value: 'eur' },
  { name: 'Dollaro', display: '$', value: 'usd' },
];

export const SESSION_STORAGE_DYNAMIC_LANGUAGE = 'dynamicLanguage';

export const LANGUAGES = [
  { name: 'Italiano', value: 'it' },
  { name: 'English', value: 'en' },
];

export const MAX_SIMULATION_LEADERBOARD_LENGTH = 100;
export const MAX_USER_PERSONAL_LEADERBOARD_LENGTH = 5;

export const WAVESURFER_DEFAULT_OPTIONS: Partial<WaveSurferOptions> = {
  waveColor: '#475186',
  progressColor: '#006dca',
  height: 30,
  barGap: 4,
  autoCenter: true,
  barWidth: 4,
  fillParent: true,
  barRadius: 5,
  cursorWidth: 0,
};
